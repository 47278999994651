import PERMISSIONS from "../../graphql/query/GetPermissions.gql";
import service from "@/lib/service";

export const namespaced = true;

export const state = {};

export const mutations = {
  SET_PERMISSIONS_DATA(state, data) {
    state = { ...data };
  },
};

export const actions = {
  async getPermissions({ commit }, { apolloClient, data }) {
    try {
      const {
        data: { GetPermissions },
      } = await apolloClient.query({
        query: PERMISSIONS,
        variables: {
          path: data.path,
          contentType: data.contentType,
        },
        fetchPolicy: "network-only",
      });
      return { ...GetPermissions, fields: JSON.parse(GetPermissions.fields) };
    } catch (error) {
      throw error.graphQLErrors;
    }
  },
  async uploadImage({ commit }, { url, form }) {
    try {
      const response = await service.custom(url, form);

      return true;
    } catch (error) {
      throw [error];
    }
  },
};

export const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
