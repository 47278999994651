import store from "../../store";
import { createClient } from "../../vue-apollo";

const functional = [
  {
    path: "/bienvenida",
    alias: "/invitation",
    name: "Invitation",
    meta: {
      layout: "Login",
      requires: {
        loggedOut: true,
      },
    },
    component: () =>
      import(/* webpackChunkName: "welcome" */ "@/pages/Invitation.vue"),
  },
  {
    path: "/referencia",
    alias: "/about",
    name: "About",
    meta: {
      layout: "FullHeight",
    },
    component: () => import(/* webpackChunkName: "about" */ "@/pages/About.vue"),
  },
  {
    path: "/acceder",
    alias: "/login",
    name: "Login",
    meta: {
      layout: "Blank",
      requires: {
        loggedOut: true,
      },
    },
    component: () => import(/* webpackChunkName: "login" */ "@/pages/Login.vue"),
  },
  {
    path: "/recuperar",
    alias: "/recover",
    name: "Recover",
    meta: {
      layout: "Blank",
      requires: {
        loggedOut: true,
      },
    },
    component: () => import(/* webpackChunkName: "login" */ "@/pages/Recover.vue"),
  },
  {
    path: "/registro",
    alias: "/register",
    name: "Register",
    meta: {
      layout: "Login",
      requires: {
        loggedOut: true,
      },
    },
    component: () => {
      // store.dispatch("notifications/callSnackbar", {
      //   color: "warning",
      //   content:
      //     "Registro deshabilitado. Si deseas registrarte envia un email a hola@yourney.io",
      //   timeout: 10000,
      // });
      return import(/* webpackChunkName: "login" */ "@/pages/Login.vue");
      // habilitar pagina de Registro cuando se deba
      //  import(/* webpackChunkName: "register" */ "@/pages/Coachee/Register.vue"),
    },
  },
  {
    path: "/cambio/:token?",
    alias: "/change/:token?",
    name: "Change",
    props: true,
    meta: {
      layout: "Blank",
      requires: {
        loggedOut: true,
      },
    },
    component: () =>
      import(/* webpackChunkName: "verify" */ "@/pages/ChangePassword.vue"),
  },
  {
    path: "/verificacion/:token?",
    alias: "/verify/:token?",
    name: "Verify",
    meta: {
      layout: "Login",
      requires: {
        unverified: true,
      },
    },
    component: () => import(/* webpackChunkName: "verify" */ "@/pages/Verify.vue"),
    async beforeEnter(routeTo, routeFrom, next) {
      const apolloClient = createClient();
      let isLoggedIn = store.getters["users/isLoggedIn"];
      if (routeTo.params.token) {
        try {
          await store.dispatch("users/activate", {
            token: routeTo.params.token,
            apolloClient,
          });
          next({ name: "Home" });
        } catch (e) {}
      }
      if (!isLoggedIn) {
        next({ name: "Login" });
      }
      next();
    },
  },
  {
    path: "/salir",
    alias: "/signout",
    name: "Signout",
    beforeEnter(to, from, next) {
      store.dispatch("users/signout");
      next({ name: "Login" });
    },
  },
  // Página 404 (mantener al final)
  {
    path: "/404",
    alias: "*",
    name: "Notfound",
    meta: {
      layout: "Blank",
    },
    component: () => import("@/pages/Notfound.vue"),
  },
];
export default functional;
