import Vue from "vue";
import store from "./store";
import VueApollo from "vue-apollo";
// Install the vue plugin
Vue.use(VueApollo);
import possibleTypes from "./possibleTypes.json";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Http endpoint
const httpEndpoint =
  process.env.VUE_APP_ROOT_API + process.env.VUE_APP_GRAPHQL_HTTP ||
  "http://localhost:3000/develop/graphql";
// Files URL root
// export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))
// Vue.prototype.$filesRoot = filesRoot

const httpLink = createHttpLink({
  uri: httpEndpoint,
});

const authLink = setContext((_, { headers }) => {
  const token = store.getters["users/getToken"];
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export function createClient(options = {}) {
  const apolloClient = new ApolloClient({
    ...options,
    ssrMode: false,
    link: authLink.concat(httpLink),
    defaultContext: {
      headers: {
        Authorization: `Bearer ${store.getters["users/getToken"]}`,
      },
    },
    cache: new InMemoryCache({ possibleTypes, addTypename: false }),
  });
  return apolloClient;
}

// Call this in the Vue app file
export function createProvider(options = {}) {
  const apolloClient = new ApolloClient({
    ...options,
    link: authLink.concat(httpLink),
    defaultContext: {
      headers: {
        Authorization: `Bearer ${store.getters["users/getToken"]}`,
      },
    },
    cache: new InMemoryCache({ possibleTypes, addTypename: false }),
  });

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
      query: {
        loadingKey: "loading",
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.error(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });
  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
