// Queries
import DASHBOARD_FETCH_PROGRAM_SUMMARY from "../../graphql/query/DashboardFetchProgramSummary.gql";
import DASHBOARD_FETCH_PROGRESS_SUMMARY from "../../graphql/query/DashboardFetchProgressSummary.gql";
import DASHBOARD_FETCH_SESSIONSBYWEEK from "../../graphql/query/DashboardSessionsByWeek.gql";
import DASHBOARD_FETCH_COACHEESBYPROGRAM from "../../graphql/query/DashboardFetchCoacheesByProgram.gql";
import DASHBOARD_FETCH_FORMRESPONSESBYPROGRAM from "../../graphql/query/DashboardFetchFormResponsesSummary.gql";
import DASHBOARD_FETCH_RENDER_ALIGNMENT_INDEX from "../../graphql/query/FetchRenderAlignmentIndex.gql";
export const namespaced = true;

const initState = () => {
  return {
    programSummary: null,
    sessionsByWeek: null,
    coacheesByProgram: [],
    formResponsesSummary: null,
    formResponsesSummaryRanking: null,
    progressSummary: null,
    alignmentIndex: null,
  };
};

const fetchFormResponses = async (apolloClient, filter) => {
  try {
    const {
      data: { FormResponseSummary },
    } = await apolloClient.query({
      query: DASHBOARD_FETCH_FORMRESPONSESBYPROGRAM,
      fetchPolicy: "no-cache",
      variables: {
        ...filter,
      },
    });
    return { ...FormResponseSummary };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const state = initState();

export const mutations = {
  SET_PROGRAM_SUMMARY(state, programSummary) {
    state.programSummary = programSummary;
  },
  SET_PROGRESS_SUMMARY(state, progressSummary) {
    state.progressSummary = progressSummary;
  },
  SET_SESSIONS_BY_WEEK(state, sessionsByWeek) {
    state.sessionsByWeek = sessionsByWeek;
  },
  SET_COACHEES_BY_PROGRAM(state, coacheesByProgram) {
    state.coacheesByProgram = coacheesByProgram;
  },
  SET_FORM_RESPONSES(state, formResponsesSummary) {
    state.formResponsesSummary = formResponsesSummary;
  },
  SET_ALIGNMENT_INDEX(state, alignmentIndex) {
    state.alignmentIndex = alignmentIndex;
  },
  SET_FORM_RESPONSES_RANKING(state, formResponsesSummary) {
    state.formResponsesSummaryRanking = formResponsesSummary;
  },
};

export const actions = {
  async fetchProgramSummary({ commit }, { apolloClient, filter }) {
    try {
      commit("SET_PROGRAM_SUMMARY", null);
      const {
        data: { DashboardFetchProgramSummary },
      } = await apolloClient.query({
        query: DASHBOARD_FETCH_PROGRAM_SUMMARY,
        fetchPolicy: "no-cache",
        variables: {
          data: { filter },
        },
      });
      commit("SET_PROGRAM_SUMMARY", { ...DashboardFetchProgramSummary });
      return { ...DashboardFetchProgramSummary };
    } catch (error) {
      // console.error(error);
      throw error;
    }
  },
  async fetchProgressSummary({ commit }, { apolloClient, variables }) {
    commit("SET_PROGRESS_SUMMARY", null);
    const {
      data: { GetProgressSummary },
    } = await apolloClient.query({
      query: DASHBOARD_FETCH_PROGRESS_SUMMARY,
      fetchPolicy: "no-cache",
      variables,
    });
    commit("SET_PROGRESS_SUMMARY", GetProgressSummary);
    return GetProgressSummary;
  },
  async fetchSessionsByWeek({ commit }, { apolloClient, filter }) {
    try {
      commit("SET_SESSIONS_BY_WEEK", null);
      const {
        data: { DashboardFetchSessionsByWeek },
      } = await apolloClient.query({
        query: DASHBOARD_FETCH_SESSIONSBYWEEK,
        fetchPolicy: "no-cache",
        variables: {
          data: { filter },
        },
      });
      commit("SET_SESSIONS_BY_WEEK", { ...DashboardFetchSessionsByWeek });
      return { ...DashboardFetchSessionsByWeek };
    } catch (error) {
      throw error;
    }
  },
  async fetchCoacheesByProgram({ commit }, { apolloClient, filter }) {
    try {
      commit("SET_COACHEES_BY_PROGRAM", []);
      const {
        data: { DashboardFetchCoacheesByProgram },
      } = await apolloClient.query({
        query: DASHBOARD_FETCH_COACHEESBYPROGRAM,
        fetchPolicy: "no-cache",
        variables: {
          data: { filter },
        },
      });
      commit("SET_COACHEES_BY_PROGRAM", [...DashboardFetchCoacheesByProgram]);
      return [...DashboardFetchCoacheesByProgram];
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async fetchFormResponses({ commit }, { apolloClient, filter }) {
    try {
      const formResponses = await fetchFormResponses(apolloClient, filter);
      commit("SET_FORM_RESPONSES", { ...formResponses });
      return { ...formResponses };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async fetchFormResponsesRanking({ commit }, { apolloClient, filter }) {
    try {
      const formResponses = await fetchFormResponses(apolloClient, {
        programId: filter.programId,
        context: "ranking",
      });
      commit("SET_FORM_RESPONSES_RANKING", formResponses);
      return { ...formResponses };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async fetchAlignmentIndex({ commit }, { apolloClient, variables }) {
    try {
      const {
        data: { RenderAlignmentIndex },
      } = await apolloClient.query({
        query: DASHBOARD_FETCH_RENDER_ALIGNMENT_INDEX,
        fetchPolicy: "no-cache",
        variables,
      });
      commit("SET_ALIGNMENT_INDEX", RenderAlignmentIndex);
      return RenderAlignmentIndex;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getters = {
  currentWeek: (state) => state.sessionsByWeek?.currentWeek ?? 0,
  weeksLabels: (state) => state.sessionsByWeek?.labels,
  getProgramSummary: (state) => ({ ...state.programSummary }),
  getSessionsByWeek: (state) => ({ ...state.sessionsByWeek }),
  getCoacheesByProgram: (state) => [...state.coacheesByProgram],
  getFormResponsesSummary: (state) => ({ ...state.formResponsesSummary }),
  getFormResponsesSummaryRanking: (state) => ({
    ...state.formResponsesSummaryRanking,
  }),
  progressSummary: (state) => state.progressSummary,
  getAlignmentIndex: (state) => state.alignmentIndex,
};
