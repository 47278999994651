const moment = require("moment-timezone");
const roleDisplayTexts = require("../assets/json/userRoles.json");

const sleep = (time) => new Promise((resolve, reject) => setTimeout(resolve, time));

const mergeText = (text, replacement) => text.replace(/\|(.*)\|/, replacement);

const mergeTags = (text, tags) => {
  let mergedText = text;
  tags.forEach(({ tag, replacement }) => {
    mergedText = mergedText.replace(`|${tag}|`, replacement);
  });
  return mergedText;
};

const datasetsHaveData = (datasets) => {
  if (!datasets || !datasets.length) return false;
  return datasets.some((dataset) => dataset.data.some((data) => data !== 0));
};

const filterBetweenDates = (value, filter) => {
  if (filter[0] === "" && filter[1] === "") return true;
  if (filter[0] === "") return moment(value) <= moment(filter[1]).endOf("days");
  if (filter[1] === "") return moment(value) >= moment(filter[0]).startOf("days");
  return (
    moment(value) >= moment(filter[0]).startOf("days") &&
    moment(value) <= moment(filter[1]).endOf("days")
  );
};

const formatDate = (date, format = "DD/MM/YYYY") => {
  return moment(date).format(format);
};

const getDuration = (
  startDate,
  finishDate,
  dateInputFormat = "DD/MM/YYYY",
  timeUnit = "M",
  roundMethod = Math.round
) => {
  const startMoment = moment(startDate, dateInputFormat);
  const finishMoment = moment(finishDate, dateInputFormat);
  const duration = moment.duration(finishMoment.diff(startMoment));
  if (roundMethod) return roundMethod(duration.as(timeUnit));
  return duration.as(timeUnit);
};

const momentFromUnix = (unixTimestamp) => {
  return moment.unix(unixTimestamp / 1000);
};

const cleanString = (string) => string.trim().toLowerCase();

const findString = (searched, filter) => {
  const foundIndex = searched.search(new RegExp(filter));
  if (foundIndex === -1) return false;
  return true;
};

const getCleanFullName = (firstName, lastName) =>
  cleanString(firstName) + " " + cleanString(lastName);

const filterFullName = (value, filter) => {
  if (!filter) return true;
  if (!value) return false;
  return findString(
    getCleanFullName(value.name.first, value.name.last),
    cleanString(filter)
  );
};

const states = {
  pending: "pending",
  active: "active",
  finished: "finished",
  future: "future",
};

const checkPrograms = (program) => {
  let _program = {};
  if (!program) _program = { ...program };
  else _program = { ...program };

  const startAt =
    moment(_program?.startAt).startOf("day") ||
    moment(_program?.createdAt).startOf("day");
  const expiredAt = moment(_program?.expiredAt).endOf("day");
  const now = moment();

  if (moment(startAt).startOf("day").isBefore(now)) return states.pending;
  if (moment(startAt).startOf("day").isBetween(now, expiredAt)) return states.active;
  if (moment(startAt).startOf("day").isAfter(now)) return states.future;
  if (moment(expiredAt).isAfter(now)) return states.finished;
  throw TypeError("STATUS_NOT_FOUND");
};

const getActiveProgram = (programs) => {
  if (programs.length === 1) return { ...programs[0] };
  const activePrograms = programs.filter((p) => {
    const state = checkPrograms(p);
    if (states.active === state) return { ...p, state };
  });

  const sortPrograms = activePrograms.sort((a, b) => a.startAt - b.startAt);
  return { ...sortPrograms[0] };
};

const formatChartLabels = (labels) => {
  if (!labels || !labels[0]) return [];
  const currentLabel = labels[0].split(" ")[0];
  const nextLabel = labels.length >= 14 ? "Sem" : "Semana";
  const shouldChangeLabel = currentLabel !== nextLabel;
  if (!shouldChangeLabel) return labels;
  return labels.map((label) => label.replace(currentLabel, nextLabel));
};

const formatCLPCurrency = (number) => {
  // Check if the input is a valid number
  if (typeof number !== "number" || isNaN(number)) {
    throw new Error("Invalid input. Please provide a valid number.");
  }

  // Create a NumberFormat object with the desired options
  const formatter = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  // Format the number and return the result
  return formatter.format(number);
};

const getRoleDisplayText = (userType, userRole) => {
  return roleDisplayTexts[userType].find((role) => role.value === userRole).text;
};

function mergeObjects(obj1, obj2) {
  // Create a deep copy of the first object to preserve its structure
  const result = structuredClone(obj1);

  // Helper function to recursively merge objects
  function recursiveMerge(target, source) {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (source[key] !== null && source[key] !== undefined) {
          // If the property in the source object is not null or undefined,
          // update the corresponding property in the target object
          if (source[key] instanceof Object && key in target) {
            // If the current property is an object, recurse into it
            recursiveMerge(target[key], source[key]);
          } else {
            // Otherwise, update the property in the target object
            target[key] = source[key];
          }
        }
      }
    }
  }

  // Start the recursive merging process
  recursiveMerge(result, obj2);

  return result;
}

/**
 * Calculate the percentage of a numeric amount relative to a calculus base.
 *
 * @param {number} amount - The numeric amount.
 * @param {number} base - The calculus base.
 * @returns {number} The calculated percentage
 * @throws {Error} If either amount or base is not a number, or base is zero.
 * @example
 * // Returns 50
 * calculatePercentage(50, 100);
 * @example
 * // Throws an error: "Invalid input. Please provide valid numeric values for amount and base, and ensure base is not zero."
 * calculatePercentage('abc', 0);
 */
const calculatePercentage = (amount, base) => {
  if (typeof amount !== "number" || typeof base !== "number" || base === 0) {
    throw new Error(
      "Invalid input. Please provide valid numeric values for amount and base, and ensure base is not zero."
    );
  }
  return (amount / base) * 100;
};

const getBrowserInfo = (userAgent = navigator.userAgent) => {
  if (/Firefox\/([\d.]+)/.test(userAgent)) {
    const version = userAgent.match(/Firefox\/([\d.]+)/)[1];
    return { browserName: "Mozilla Firefox", browserVersion: version };
  } else if (/SamsungBrowser\/([\d.]+)/.test(userAgent)) {
    const version = userAgent.match(/SamsungBrowser\/([\d.]+)/)[1];
    return { browserName: "Samsung Internet", browserVersion: version };
  } else if (/Opera|OPR\/([\d.]+)/.test(userAgent)) {
    const version = userAgent.match(/(Opera|OPR)\/([\d.]+)/)[2];
    return { browserName: "Opera", browserVersion: version };
  } else if (/Edg\/([\d.]+)/.test(userAgent)) {
    const version = userAgent.match(/Edg\/([\d.]+)/)[1];
    return { browserName: "Microsoft Edge (Chromium)", browserVersion: version };
  } else if (/Edge\/([\d.]+)/.test(userAgent)) {
    const version = userAgent.match(/Edge\/([\d.]+)/)[1];
    return { browserName: "Microsoft Edge (Legacy)", browserVersion: version };
  } else if (/Chrome\/([\d.]+)/.test(userAgent)) {
    const version = userAgent.match(/Chrome\/([\d.]+)/)[1];
    return { browserName: "Google Chrome or Chromium", browserVersion: version };
  } else if (/Safari\/([\d.]+)/.test(userAgent)) {
    const version = userAgent.match(/Version\/([\d.]+)/)[1];
    return { browserName: "Apple Safari", browserVersion: version };
  } else {
    return { browserName: "unknown", browserVersion: "unknown" };
  }
};

export {
  datasetsHaveData,
  sleep,
  mergeObjects,
  mergeText,
  mergeTags,
  filterBetweenDates,
  cleanString,
  findString,
  getCleanFullName,
  filterFullName,
  getActiveProgram,
  formatChartLabels,
  formatCLPCurrency,
  formatDate,
  getDuration,
  getRoleDisplayText,
  momentFromUnix,
  calculatePercentage,
  getBrowserInfo,
};
